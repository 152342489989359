import { Box, Stack, Typography } from "@mui/material";
import { styles } from "./styles";

export function Footer() {
  return (
    <Stack
      component="footer"
      alignItems="center"
      justifyContent="center"
      sx={styles.footer}
    >
      <a
        href="https://reduc.ai"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h6" sx={styles.footerText}>
            Powered by
          </Typography>
          <Box
            component="img"
            src="/logo.png"
            alt="reduc logo"
            sx={styles.footerLogo}
          />
        </Stack>
      </a>
    </Stack>
  );
}
