import { Theme } from "@mui/material";

export const styles = {
  footer: {
    backgroundColor: "#121113",
    py: 4,
  },
  footerText: {
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.common.white,
  },
  footerLogo: {
    height: 50,
    objectFit: "contain",
    width: 130,
  },
};
